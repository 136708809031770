import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Thrills Casino`}</h1>
    <p>{`Snart så öppnar det nya nätcasinot Thrills. Som man kan höra på namnet som de har valt så kommer detta casino att fokusera kring upplevelsen och spänningen av att spela Casino. De kommer att redan från start ha ett stort utbud på över 400 spel och alla spel samt hela sidan kommer att vara på svenska. De kommer även att ha svenskt support från dag ett.`}</p>
    <p>{`Skaparna bakom Thrills är personer som har jobbat länge inom spelbranchen och nu har slagit sig fria för att kunna skapa den bästa tänkbara Casino upplevelsen.`}</p>
    <p>{`Vi inväntar lansering av Thrills Casino med spänning och ser fram emot ännu ett Casino som kan leverera bra Casino upplevelser och kampanjer till sina kunder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      